import Repository from '@/api/core'
const baseURL = process.env.VUE_APP_API_DELIVERY + '/delivery-bill'
const deliveryBillHistoryURL = process.env.VUE_APP_API_DELIVERY + '/delivery-bill-history'
// import axios from 'axios'
// import auth from '@/store/modules/auth'
import queryString from 'query-string'
// import vendor from '@/store/modules/vendor'

const fetch = (params) => {
  const query = queryString.stringify({
      ...params,
      // vendorId: vendor.state.vendor.vendorId
    })
    return Repository(baseURL + `?${query}`).fetch()
}

const count = (params) => {
  return Repository(baseURL + '/count').fetch(params)
}

const fetchOne = (id) => {
  return Repository(baseURL).fetchOne(id)
}

const create = (params) => {
  return Repository(baseURL + '/create').create(params)
}

const update = (id, params) => {
  return Repository(baseURL).update(id, params)
}
const updateMany = (params) => {
    return Repository(baseURL + '/update-many').create(params)
}
const remove = (id) => {
  return Repository(baseURL).delete(id)
}

const createMany = (params) => {
  return Repository(baseURL).createMany(params)
}

const fetchDeliveryBillHistory = (params) => {
  return Repository(deliveryBillHistoryURL).fetch(params)
}

export default {
  fetch,
  fetchOne,
  create,
  createMany,
  update,
  remove,
  count,
  updateMany,
  fetchDeliveryBillHistory
}
