import dayjs from 'dayjs'
import get from 'lodash-es/get'
import helpers from '@/helpers'
import auth from '@/store/modules/auth'
import stringUtils from './components/stringUtils'
import examUtils from './components/examUtils'
import matrixUtils from './components/matrixUtils'
import imageEditorUtils from './components/imageEditorUtils'
import mimeTypes from 'mime-types'
import vuetify from '@/plugins/vuetify'

const onDownloadFile = (link = '', fromS3 = true) => {
  if (typeof link === 'string' && link.length) {
    // normlize to NFD (Composite Unicode handler),
    // latin characters will be untouched
    let url = link.normalize('NFD')
    // ensure link from s3 has been properly wrapped with aws-cdn
    if (fromS3) {
      if (!link.includes('http')) url = combineLinkWithBucket(url)
      url = convertURL(url)
    }
    // ensure URL is encoded to avoid unicode characters in URL,
    // which may caused URL broken and cannot be downloaded
    url = encodeURI(url)
    window.open(url, '_blank', 'noopener noreferrer')
  }
}

const combineLinkWithBucket = (linkResource) => {
  if (linkResource && linkResource.includes('http')) {
    return linkResource
  } else {
    return process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT + '/' + linkResource
  }
}

const isAdmin = () => {
  return getRolePermission() && getRolePermission().name === 'admin'
}
const isKol = () => {
  return getRolePermission() && getRolePermission().name === 'kol'
}

const isTeacher = () => {
  return getRolePermission() && getRolePermission().name === 'teacher'
}

const exportFileExcel = async (data, fileName) => {
  const XLSX = await import('xlsx')
  let wb = XLSX.utils.book_new()
  let wsStudent = XLSX.utils.json_to_sheet(data)
  XLSX.utils.book_append_sheet(wb, wsStudent, 'result')
  XLSX.writeFile(wb, `${fileName}-${dayjs().format('DD/MM/YYYY')}.xlsx`)
}

const mapDataBillExportExcel = (bills) => {
  return bills.map((bill) => {
    const {date, time, content, price, payment, state, student} = bill
    let stateTrans = ''
    if (state === 'rejected') stateTrans = 'Từ chối'
    else if (state === 'returned') stateTrans = 'Đã trả'
    else if (state === 'success') stateTrans = 'Hoàn tất'
    else if (state === 'failed') stateTrans = 'Thất bại'
    else if (state === 'pending') stateTrans = 'Đang chờ'
    return {
      [`Tên khách hàng`]: get(student, 'name', ''),
      [`Email khách hàng`]: get(student, 'email', ''),
      [`Số điện thoại`]: get(student, 'phone', ''),
      [`Ngày tạo`]: date,
      [`Thời gian tạo`]: time,
      [`Nội dung`]: content,
      [`Giá tiền chính thức`]: get(price, 'afterDiscount', ''),
      [`Giá tiền trước khuyến mãi`]: get(price, 'beforeDiscount', ''),
      [`Tên Coupon`]: get(payment, 'couponCode', ''),
      [`Hình thức thanh toán`]: get(payment, 'purchaseType', ''),
      [`Đơn vị tiền`]: get(price, 'currency', ''),
      [`Trạng thái`]: stateTrans,
    }
  })
}
const formatDateTime = (dateTimeStr) => {
  if (!dateTimeStr) return ''; 
  const year = dateTimeStr.slice(0, 4);
  const month = dateTimeStr.slice(4, 6);
  const day = dateTimeStr.slice(6, 8);
  const hours = dateTimeStr.slice(8, 10);
  const minutes = dateTimeStr.slice(10, 12);
  const seconds = dateTimeStr.slice(12, 14);

  return `${year}-${month}-${day}, ${hours}:${minutes}:${seconds}`;
};
const mapDataBillCustomExportExcel = (bills = [], deliveryBills = [], billAutoHistorys = []) => {
  console.log(billAutoHistorys);
  const newBills = bills.flatMap((bill) =>
    bill.products.map((product) => ({
      ...bill,
      products: product,
      deliveryBill: deliveryBills.find((item) => item.billId == bill.id),
      billAutoHistory: billAutoHistorys.find((item) => item.billId == bill.id),
    }))
  )
  return newBills.map((bill) => {
    let stateTrans = ''
    let purchaseType = ''
    if (bill?.state === 'rejected') stateTrans = 'Từ chối'
    else if (bill?.state === 'returned') stateTrans = 'Đã trả'
    else if (bill?.state === 'success') stateTrans = 'Hoàn tất'
    else if (bill?.state === 'failed') stateTrans = 'Thất bại'
    else if (bill?.state === 'pending') stateTrans = 'Đang chờ'
    if (bill?.payment?.purchaseType === 'transfer') purchaseType = 'Chuyển khoản'
    else purchaseType = 'Thanh toán khi nhận hàng'
    return {
      [`Mã đơn hàng`]: get(bill, 'shortenId', ''),
      [`Họ tên khách hàng`]: get(bill.student, 'name', ''),
      [`Số điện thoại`]: get(bill.student, 'phone', ''),
      [`Email`]: get(bill.student, 'email', ''),
      [`Ngày tạo`]: bill?.date,
      [`Mã khoá học`]: get(bill, 'products.id', ''),
      [`Tên khoá học`]: get(bill, 'products.title', ''),
      [`Số lượng`]: '1',
      [`Giá tiền gốc`]: get(bill, 'products.beforeDiscount', ''),
      [`Mã khuyến mãi`]: get(bill, 'payment.couponCode', ''), //chưa xong
      [`Tiền khuyến mãi`]: get(bill, 'price.discount.coupon', ''), //cần xem lại
      [`Giá sau khuyến mãi`]: get(bill, 'products.afterDiscount', ''),
      [`Hình thức thanh toán`]: purchaseType,
      [`Trạng thái`]: stateTrans,
      // history api
      [`Ngày thanh toán`]: formatDateTime(bill?.billAutoHistory?.data?.transactionTime?.text),
      [`Mã Tham chiếu ngân hàng`]: bill?.billAutoHistory?.data?.transactionId || '',
      [`Yêu cầu xuất hoá đơn`]: '',
      [`Mã số thuế`]: '',
      [`Tên công ty`]: '',
      [`Địa chỉ`]: '',
      [`Email nhận hoá đơn`]: '',
      // delivery bill
      [`Tên người nhận`]: get(bill, 'deliveryBill.recipientName', ''),
      [`Số điện thoại người nhận`]: get(bill, 'deliveryBill.recipientPhone', ''),
      [`Địa chỉ người nhận`]: bill?.deliveryBill?.recipientAddress ? `${bill?.deliveryBill?.recipientAddress?.address}, ${bill?.deliveryBill?.recipientAddress?.wards?.name}, ${bill?.deliveryBill?.recipientAddress?.districts?.name}, ${bill?.deliveryBill?.recipientAddress?.provinces?.name}, ` : '',
      [`Số nhà, ngõ, ngách, thôn`]: get(bill, 'deliveryBill.recipientAddress.address', ''),
      [`Xã, Phường, Thị Trấn`]: get(bill, 'deliveryBill.recipientAddress.wards.name', ''),
      [`Quận, Huyện, Thành phố trực thuộc Tỉnh`]: get(bill, 'deliveryBill.recipientAddress.districts.name', ''),
      [`Tỉnh, Thành phố`]:get(bill, 'deliveryBill.recipientAddress.provinces.name', ''),
      [`Trạng thái giao hàng`]: get(bill, 'deliveryBill.deliveryStatus', ''),
      [`Mã vận đơn`]: get(bill, 'deliveryBill.trackingNumber', ''),
    }
  })
}
const mapDataOrderExportExcel = (orders) => {
  return orders.map((order) => {
    const {createdAt, customerEmail, customerName, customerPhone, items, refBy} = order
    let name = ''
    let referrer = ''
    if (items && items[0]) {
      name = items[0].title
    }
    if (refBy) referrer = refBy.name
    return {
      [`Ngày`]: createdAt ? dayjs(createdAt).format('DD/MM/YYYY') : '',
      [`Tên học viên`]: customerName || '',
      [`Email`]: customerEmail || '',
      [`Số điện thoại`]: customerPhone || '',
      [`Tên sản phẩm`]: name,
      [`Giới thiệu bởi`]: referrer,
    }
  })
}
const mapDataDeliveryBillExportExcel = (orders) => {
  return orders.map((order) => {
    const {productCode, trackingNumber} = order
    return {
      [`Mã đơn hàng`]: productCode || '',
      [`Mã vận đơn`]: trackingNumber || '',
    }
  })
}
const mapDataContactExportExcel = (Contacts) => {
  return Contacts.map((contact) => {
    const {createdAt, email, name, phone, metadata, refBy} = contact
    let nameProduct = ''
    let referrer = ''
    if (metadata && metadata.interestedCourse) {
      nameProduct = metadata.interestedCourse.title
    }
    if (refBy) referrer = refBy.name
    return {
      [`Ngày`]: createdAt ? dayjs(createdAt).format('DD/MM/YYYY') : '',
      [`Tên học viên`]: name || '',
      [`Email`]: email || '',
      [`Số điện thoại`]: phone || '',
      [`Tên sản phẩm quan tâm`]: nameProduct,
      [`Giới thiệu bởi`]: referrer,
    }
  })
}
const convertDuration = (duration) => {
  let tDuration = duration || 0
  if (isNaN(tDuration)) {
    return tDuration
  } else {
    let tH = Math.floor(tDuration / (60 * 60))
    if (tH > 9) {
      let tH2 = Math.round(tDuration / (60 * 60))
      if (tH2 > tH) {
        return `${vuetify.framework.lang.translator('$vuetify.TEXT_ALMOST', tH2)}`
      } else if (tH == tH2) {
        if (tH * 60 * 60 == tDuration) {
          return `${tH2} ${vuetify.framework.lang.translator('$vuetify.TEXT_HOUR')}`
        } else {
          return `${vuetify.framework.lang.translator('$vuetify.TEXT_MORE_THAN', tH2)}`
        }
      }
    } else if (tH > 0) {
      let tM = Math.round((tDuration - tH * 60 * 60) / 60)
      if (tM > 0) {
        return `${tH} ${vuetify.framework.lang.translator(
          '$vuetify.TEXT_HOUR'
        )} ${tM} ${vuetify.framework.lang.translator('$vuetify.TEXT_MINUTE')}`
      }
      return `${tH} ${vuetify.framework.lang.translator('$vuetify.TEXT_HOUR')}`
    } else {
      let tM = Math.round(tDuration / 60)
      if (tM > 0) return `${tM} ${vuetify.framework.lang.translator('$vuetify.TEXT_MINUTE')}`
      return `${tDuration} ${vuetify.framework.lang.translator('$vuetify.TEXT_SECOND')}`
    }
  }
}

const convertURL = (url, withoutCloudfront) => {
  if (!url) return url
  let convertedUrl = url
  if (!url.includes('https') && url.includes('http')) {
    let urlWithHttps = url.replace('http', 'https')
    convertedUrl = urlWithHttps
  }
  const pattern = /https:\/\/online-learning-izteach-\d-aws-source-bucket\.s3-ap-southeast-1\.amazonaws\.com/
  const replacement = process.env.VUE_APP_AWS_BUCKET_CLOUD_FRONT
  return withoutCloudfront ? convertedUrl : convertedUrl.replace(pattern, replacement)
}

const getTypeNameUnit = function (type) {
  switch (type) {
    case 'video':
    case 'vimeo':
      return vuetify.framework.lang.translator('$vuetify.LABEL_VIDEO')
    case 'pdf':
      return vuetify.framework.lang.translator('$vuetify.LABEL_PDF_SLIDE')
    case 'test':
      return vuetify.framework.lang.translator('$vuetify.LABEL_TEST')
    case 'exercise':
      return vuetify.framework.lang.translator('$vuetify.LABEL_EXERCISE')
    case 'homework':
      return vuetify.framework.lang.translator('$vuetify.LABEL_HOMEWORK')
    case 'matrix':
      return vuetify.framework.lang.translator('$vuetify.LABEL_MATRIX')
    case 'audio':
      return vuetify.framework.lang.translator('$vuetify.LABEL_AUDIO')
    case 'reading-test':
      return vuetify.framework.lang.translator('$vuetify.LABEL_READING_TEST')
    case 'listening-test':
      return vuetify.framework.lang.translator('$vuetify.LABEL_LISTENING_TEST')
  }
}
const validateTimeForLesson = (setting) => {
  if (setting && setting.hasRangeTime) {
    const timeZone = dayjs().valueOf()
    const startTime = dayjs(`${setting.rangeTimeFromDate}T${setting.rangeTimeFromHour}:00.000+07:00`).valueOf()
    const endTime = dayjs(`${setting.rangeTimeToDate}T${setting.rangeTimeToHour}:00.000+07:00`).valueOf()
    return timeZone > startTime && timeZone < endTime
  } else return true
}

const getLinkWithoutCache = (link) => {
  const convertedLink = convertURL(link)
  return `${convertedLink}?time=${Date.now()}`
}

const getRolePermission = () => {
  const {PERMISSION} = helpers
  console.log('//todo', PERMISSION)
  const role = get(auth.state.auth, 'roles.0')
  const rolePermissionKey = Object.keys(PERMISSION).find((key) => {
    return PERMISSION[key] && PERMISSION[key].name === role
  })
  const rolePermission = PERMISSION[rolePermissionKey]
  return rolePermission || {}
}

const importFileExcelAndGetData = async (file, colRangeIndex, richCellRangeStart = 0, richCellRangeEnd = 0) => {
  const XLSX = await import('xlsx')
  let dataExcel = []
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = async (evt) => {
      const bstr = evt.target.result
      const wb = XLSX.read(bstr, {type: 'binary'})
      wb.SheetNames.forEach((sheetName) => {
        const currentSheet = wb.Sheets[sheetName]
        const colRange = get(currentSheet['!ref'], '3', '')
        if (colRange && colRange == helpers.DEFAULT_LABELS[colRangeIndex]) {
          const rows = XLSX.utils.sheet_to_json(currentSheet, {defval: ''})
          rows.forEach((row, index) => {
            dataExcel.push({
              ...row,
              ..._richCellExtract(row, index, currentSheet, richCellRangeStart, richCellRangeEnd),
              rowIndex: index,
            })
          })
        } else {
          reject({
            type: 'wrong_template',
            message: vuetify.framework.lang.translator('$vuetify.MSG_FILE_FOMAT_FALSE'),
          })
        }
      })
      resolve(dataExcel)
    }
    reader.readAsBinaryString(file)
  })
}

const _richCellExtract = (row, rowIndex, currentSheet, rangeStart = 0, rangeEnd = 0) => {
  const rowKeys = Object.keys(row)
  const richCells = {}
  for (let i = rangeStart; i < rangeEnd; i++) {
    const cell = row[rowKeys[i]]
    if (cell) {
      richCells[rowKeys[i]] = get(currentSheet[helpers.DEFAULT_LABELS[i] + (rowIndex + 2)], 'h', cell)
    }
  }
  return richCells
}

const getDateFromSetting = (settings, type) => {
  if (!settings.hasRangeTime) return
  if (type === 'start') {
    return new Date(settings.rangeTimeFromHour + ' ' + settings.rangeTimeFromDate)
  } else if (type === 'end') {
    return new Date(settings.rangeTimeToHour + ' ' + settings.rangeTimeToDate)
  }
}

const isImage = (extension) => {
  return /\.(gif|jpe?g|tiff?|png|webp|bmp)/i.test(`.${extension}`)
}

const isVideo = (extension) => {
  return /\.(mp4|mkv|m4v|mov|qt)/i.test(`.${extension}`)
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

const getBillStatus = (status) => {
  switch (status) {
    case helpers.BILL_STATE.REJECTED:
      return vuetify.framework.lang.translator('$vuetify.REJECTED')
    case helpers.BILL_STATE.SUCCESS:
      return vuetify.framework.lang.translator('$vuetify.SUCCESS')
    case helpers.BILL_STATE.PENDING:
      return vuetify.framework.lang.translator('$vuetify.PENDING')
    case helpers.BILL_STATE.FAILED:
      return vuetify.framework.lang.translator('$vuetify.FAILED')
    case helpers.BILL_STATE.RETURNED:
      return vuetify.framework.lang.translator('$vuetify.RETURNED')
    default:
      return ''
  }
}

const getBillStatusColor = (state) => {
  switch (state) {
    case helpers.BILL_STATE.SUCCESS:
      return '#4BCA81'
    case helpers.BILL_STATE.PENDING:
      return '#FF1D30'
    case helpers.BILL_STATE.REJECTED:
      return '#9C9C9C'
    default:
      return '#9C9C9C'
  }
}

const getPaymentMethod = (method) => {
  switch (method) {
    case helpers.PAYMENT_METHOD.HAND_ON:
      return vuetify.framework.lang.translator('$vuetify.LABEL_TRANSFER')
    case helpers.PAYMENT_METHOD.BANK_TRANSFER:
      return vuetify.framework.lang.translator('$vuetify.LABEL_TRANSFER')
    case helpers.PAYMENT_METHOD.DIRECT_PAY:
      return vuetify.framework.lang.translator('$vuetify.LABEL_DIRECT_PAY')
    case helpers.PAYMENT_METHOD.VN_PAY:
      return vuetify.framework.lang.translator('$vuetify.LABEL_VN_PAY')
    case helpers.PAYMENT_METHOD.COD:
      return 'COD'
    case helpers.PAYMENT_METHOD.ADMIN:
      return vuetify.framework.lang.translator('$vuetify.LABEL_ADMIN_CREATED')
    case helpers.PAYMENT_METHOD.ACTIVE_CODE:
      return vuetify.framework.lang.translator('$vuetify.LABEL_ACTIVATION_CODE')
    default:
      return 'Chuyển khoản'
  }
}

const getVimeoDuration = (courseUnits = [], currentUnit = {}) => {
  if (Array.isArray(courseUnits) && courseUnits.length) {
    return getAllVideoDurationFromUnits(courseUnits.filter((unit) => unit.id !== currentUnit.id))
  }
  return 0
}

const getExamDuration = (exams, currentUnit) => {
  return exams
    .filter((exam) => {
      return (
        exam.id !== currentUnit.exam &&
        exam.settings &&
        (exam.settings.hasLimitedTime || exam.settings.isUseCountDownTime)
      )
    })
    .map((v) => parseInt(v.settings.expiredTime))
    .reduce((a, b) => a + b, 0)
}

const isUpdateDuration = (type, course) => {
  return (
    (type === 'vimeo' ||
      type === 'video' ||
      type === 'test' ||
      type === 'audio' ||
      type === 'matrix' ||
      type === 'exercise') &&
    course.data &&
    course.data.isCalculateDuration
  )
}

const convertDateToVietnamese = (date, isReturnToISO = false, format = 'DD/MM/YYYY') => {
  if (!isNaN(date)) {
    const referenceDate = new Date('1900-01-01')
    const targetDate = new Date(referenceDate.getTime() + (date - 2) * 24 * 60 * 60 * 1000)
    return targetDate.getMonth() + 1 + '/' + targetDate.getDate() + '/' + targetDate.getFullYear()
  }
  const customParseFormat = require('dayjs/plugin/customParseFormat')
  dayjs.extend(customParseFormat)
  if (date) {
    if (isReturnToISO) return dayjs(date, format).toISOString()
    return dayjs(date, format)
  }
  return ''
}

const getAllVideoDurationFromUnits = (units = []) => {
  if (Array.isArray(units) && units.length) {
    return units
      .filter((unit) => {
        if (unit.vimeo && unit.vimeo.duration) return true
        if (unit.video && unit.video.provider === 'VSTORAGE') return true
        return false
      })
      .map((unit) => {
        let duration = 0
        const unitVideo = unit.video
        const unitVimeo = unit.vimeo
        if (unitVideo && unitVideo.provider === 'VSTORAGE') {
          duration =
            get(unitVideo, 'metadata.duration') ||
            get(unitVideo, 'video.origin.duration') ||
            get(unitVideo, 'duration') ||
            0
        } else if (unitVimeo && unitVimeo.duration) {
          duration = unitVimeo.duration
        }
        return duration
      })
      .reduce((a, b) => a + b, 0)
  }
  return 0
}

const convertDateToDateTimeVietnamese = (date, hasSecondInTime = true) => {
  const displayedDate = dayjs(date).format('DD/MM/YYYY')
  const displayedTime = dayjs(date).format(hasSecondInTime ? 'HH:mm:ss' : 'HH:mm')
  return `${displayedDate}, ${vuetify.framework.lang.translator('$vuetify.TEXT_WHEN')} ${displayedTime}`
}

const parseFileSize = (size = 0, thres = 1024) => {
  if (isNaN(size) || Number.isNaN(size)) return ''
  const fileSizeTypes = ['B', 'KB', 'MB', 'GB', 'TB']
  let result = size,
    count = 0
  while (result >= thres) {
    result /= thres
    ++count
  }
  return `${result.toFixed(2)} ${fileSizeTypes[count]}`
}

const getPartsOfName = (str = '') => {
  const formatted = stringUtils.clearUnicode(str)
  const nameArr = formatted.split('-')
  let firstName = '',
    middleName = '',
    lastName = ''
  if (nameArr.length > 2) {
    firstName = nameArr[nameArr.length - 1]
    middleName = nameArr.slice(1, nameArr.length - 1).join(' ')
    lastName = nameArr[0]
  } else if (nameArr.length > 1) {
    firstName = nameArr[nameArr.length - 1]
    lastName = nameArr[0]
  } else {
    firstName = nameArr[0]
  }
  return {firstName, middleName, lastName}
}

const sortByNameInAlphabetical = (acc = {}, cur = {}) => {
  const accName = getPartsOfName(acc.name)
  const curName = getPartsOfName(cur.name)
  const sortByFirstName = accName.firstName.localeCompare(curName.firstName)
  if (sortByFirstName !== 0) return sortByFirstName
  const sortByLastName = accName.lastName.localeCompare(curName.lastName)
  if (sortByLastName !== 0) return sortByLastName
  return accName.middleName.localeCompare(curName.middleName)
}

const checkAllFilesInHomeworkImage = (homeworkFiles = []) => {
  let result = false
  if (Array.isArray(homeworkFiles) && homeworkFiles.length) {
    const numberOfImages = homeworkFiles.filter((item) => {
      if (item.link && item.link.length) {
        return isImage(getExtensionOfResource(item))
      }
      return false
    }).length
    result = numberOfImages === homeworkFiles.length
  }
  return result
}

const getExtensionOfResource = (item) => {
  return mimeTypes.extension(get(item, 'origin.mimeType', ''))
}

const sortWithType = (item, type) => {
  let newItem = item.sort((a, b) => {
    if (type === 'alphabet') {
      let firstString = stringUtils.clearUnicode(a.title)
      let secondString = stringUtils.clearUnicode(b.title)
      return firstString.localeCompare(secondString)
    } else if (type === 'createdAt') return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    else return a.index - b.index
  })
  return newItem.map((obj, index) => {
    return {...obj, index: index}
  })
}

const renMathInElem = (elem) => {
  let rootElem = document.body
  if (elem) rootElem = elem
  window.renderMathInElement(rootElem, {
    delimiters: [
      {left: '$$', right: '$$', display: true},
      {left: '$', right: '$', display: false},
    ],
    throwOnError: false,
    output: 'html',
    strict: false,
  })
}
const formatFileSize = (size) => {
  let videoSize = ''
  if (!size) return ''
  const sizeInKB = Math.floor(Number(size) / 1024)
  if (sizeInKB >= 1024) {
    const sizeInMB = Math.floor(sizeInKB / 1024)
    if (sizeInMB >= 1024) {
      videoSize = Math.floor(sizeInMB / 1024) + ' GB'
    }
    videoSize = sizeInMB + ' MB'
  } else {
    videoSize = sizeInKB + ' kB'
  }
  return videoSize
}

const formatTimeZone = (timeInMiliseconds) => {
  let timeConvert = '0'
  if (!isNaN(Number(timeInMiliseconds)) && timeInMiliseconds !== null) {
    const timeInSeconds = timeInMiliseconds / 1000
    let seconds = Math.floor(timeInSeconds % 60)
    let minutes = Math.floor((timeInSeconds / 60) % 60)
    let hours = Math.floor((timeInSeconds / (60 * 60)) % 24)
    seconds = seconds >= 10 && seconds !== 0 ? seconds : `0${seconds}`
    if (hours !== 0) {
      timeConvert = seconds === 0 && minutes === 0 ? `${hours}:00:00` : `${hours}:${minutes}:${seconds}`
    } else {
      timeConvert = minutes === 0 && seconds === 0 ? `0` : `${minutes}:${seconds}`
    }
  }
  return timeConvert
}

const formatTitle = (title, charlimit) => {
  if (title && title.length > charlimit) return title.slice(0, charlimit) + '...'
  else return title
}

const formatMoneyToVND = (money) => {
  if (!isNaN(Number(money)) && money !== null && money !== undefined) {
    let currentmoney = money + ''
    if (currentmoney.length !== 0) {
      currentmoney = Number(currentmoney.split('.,').join('')).toLocaleString('en-US')
    }
    return `${currentmoney} VNĐ`
  } else {
    return ''
  }
}

const removeDuplicateElementById = (prevList, newList) => {
  let listData = [...prevList, ...newList]
  let formatArr = listData.filter((value, index, self) => index === self.findIndex((val) => val.id === value.id))
  return formatArr
}

const padNumber = (number) => {
  let formatNumber = null
  if (!isNaN(number) && number !== null && typeof number !== 'string') {
    if (number >= 10) {
      formatNumber = `${number}`
    } else {
      formatNumber = `0${number}`
    }
  } else {
    formatNumber = `0`
  }
  return formatNumber
}

const handlerValidString = (str = '', defaultStr = '') => {
  if (typeof str === 'string' && str.length) {
    return str
  }
  return defaultStr
}

const handlerEvaluationCollectionName = (list) => {
  return list.reduce((result, current) => {
    const lastGroup = result[result.length - 1]
    if (lastGroup && lastGroup[0].date === current.date) {
      lastGroup.push(current)
    } else {
      result.push([current])
    }
    return result
  }, [])
}
const audioFileHasDuration = async (file) => {
  const audio = document.createElement('AUDIO')
  audio.setAttribute('src', URL.createObjectURL(file))
  audio.setAttribute('id', `audio_${file.upload.uuid}`)
  document.body.appendChild(audio)
  await delay(100)
  const duration = audio?.duration
  audio.remove()
  file.duration = duration
  return file
}
const setDurationAudioFiles = async (files) => {
  const promise = files.map((file) => {
    return audioFileHasDuration(file)
  })
  return await Promise.all(promise)
}

const generateNameToSort = (name = '') => {
  const nameArr1 = name.trim().split(' ')
  if (nameArr1[nameArr1.length - 1].length === 1) {
    name = name.slice(0, -1).trim()
  }

  const nameArr = name.toLowerCase().split(' ')
  return nameArr.slice(0, nameArr.length - 1).reduce((pre, cur) => pre + ' ' + cur, nameArr[nameArr.length - 1])
}

const generateNameToNumber = (fullname = '') => {
  const {ALPHABET_LOWERCASE} = helpers
  const numbers = generateNameToSort(fullname)
    .toLowerCase()
    .split('')
    .map((char) => ALPHABET_LOWERCASE.indexOf(char))
  return numbers.join('')
}
const draftUnitTitle = (unit) => {
  if (unit.settings?.isDraft) return `${unit.draft?.info?.title ?? unit.title}`
  return unit.title
}
const convertAttachmentToArray = (syllabus) => {
  const resources = syllabus ? [syllabus] : []
  return Array.isArray(syllabus) ? syllabus : resources
}
export default {
  convertAttachmentToArray,
  draftUnitTitle,
  setDurationAudioFiles,
  sortWithType,
  combineLinkWithBucket,
  convertURL,
  onDownloadFile,
  validateTimeForLesson,
  getLinkWithoutCache,
  getRolePermission,
  isAdmin,
  isTeacher,
  isKol,
  getTypeNameUnit,
  exportFileExcel,
  importFileExcelAndGetData,
  getDateFromSetting,
  convertDuration,
  isImage,
  delay,
  getBillStatus,
  getBillStatusColor,
  getPaymentMethod,
  getVimeoDuration,
  getExamDuration,
  isUpdateDuration,
  isVideo,
  dayjs,
  ...stringUtils,
  ...examUtils,
  ...matrixUtils,
  ...imageEditorUtils,
  convertDateToVietnamese,
  getAllVideoDurationFromUnits,
  convertDateToDateTimeVietnamese,
  parseFileSize,
  getPartsOfName,
  sortByNameInAlphabetical,
  checkAllFilesInHomeworkImage,
  getExtensionOfResource,
  mapDataBillExportExcel,
  renMathInElem,
  formatTimeZone,
  formatFileSize,
  formatTitle,
  formatMoneyToVND,
  removeDuplicateElementById,
  padNumber,
  handlerValidString,
  handlerEvaluationCollectionName,
  generateNameToNumber,
  mapDataOrderExportExcel,
  mapDataContactExportExcel,
  mapDataDeliveryBillExportExcel,
  mapDataBillCustomExportExcel,
}
